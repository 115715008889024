










































































import { Component, Vue, Watch, Prop, PropSync } from "vue-property-decorator";
@Component({
  components: {}
})
export default class DesignCard extends Vue {
  @Prop({ default: "" }) avatar!: string;
  @Prop({ default: "success" }) color!: string;
  @Prop({ default: undefined }) icon!: string;
  @Prop({ default: false }) image!: Boolean;
  @Prop({ default: "" }) text!: string;
  @Prop({ default: "" }) title!: string;
  @Prop({ default: "100%" }) width!: string;
  @Prop({ default: "100%" }) height!: string;
  @Prop({ default: "#fbab7e" }) gradientstart!: string;
  @Prop({ default: "#f7ce68" }) gradientstop!: string;
  @Prop({ default: "rgba(231, 166, 26, 0.5)" }) shadow!: string;

  public objhover!: boolean;
  public get classes() {
    return {
      "v-card--material--has-heading": this.hasHeading
    };
  }
  public get hasHeading() {
    return Boolean(this.$slots.heading || this.title || this.icon);
  }
  public get hasAltHeading() {
    return Boolean(this.$slots.heading || (this.title && this.icon));
  }
  public get style() {
    return (
      "border-radius: 5px 60px 5px 5px !important; -moz-border-radius: 5px 60px 5px 5px !important;-webkit-border-radius: 5px 60px 5px 5px !important;border: 0px solid !important;background-color:" +
      this.gradientstart +
      " !important;background-image: linear-gradient(62deg," +
      this.gradientstart +
      " 0%, " +
      this.gradientstop +
      " 100%) !important;box-shadow: " +
      this.shadow +
      " 10px 5px 29px 0px;"
    );
  }
}
